// inbound-oauth-route SCSS
@use "sass:math";

$off_white: #FAFAFB;
$smoke: #eee;
$off_black: #1E2024;
$slate: #4b4b4b;
$grey: #6d6d6d;
$mid_grey: #828B98;
$light_grey: #bbb;
$button_blue: #1874FD;
$button_hover_blue: #0084d4;
$light_blue: #c9d7df;
$very_light_blue: #f8fdff;
$nicejob_purple: #4c2080;
$button_green: #299429;
$button_hover_green: green;

$narrow_card_width: 432px;
$animation_t: 300;

$mobile_width: 640px;

$CDN: "https://cdn.nicejob.co";

* {
    padding: 0;
    border: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: Inter, Roboto, Helvetica, Arial, sans-serif;
    font-size: 0;
    min-height: 100%;
    background-color: $off_white;
}

input,
*:focus {
    outline: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
}
input[type="radio"] {
    -webkit-appearance: radio !important;
    appearance: radio !important;
}

input[type="checkbox"] {
    -webkit-appearance: checkbox !important;
    appearance: checkbox !important;
}

input.hidden {
    position: absolute;
    visibility: hidden;
    z-index: -1;
    height: 1px;
    width: 1px;
    overflow: hidden;
}

@mixin wh($wh, $lh: 0) {
    width: $wh;
    height: $wh;

    @if ($lh != 0) {
        line-height: $wh;
    }
}
@mixin max_wh($wh, $lh: 0) {
    max-width: $wh;
    max-height: $wh;

    @if ($lh != 0) {
        line-height: $wh;
    }
}
@mixin ibm() {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}
@mixin animate($property, $time, $will_change: 0, $important: 0) {
    @if ($important != 0) {
        -webkit-transition: $property #{$time}ms ease !important;
        -moz-transition: $property #{$time}ms ease !important;
        -o-transition: $property #{$time}ms ease !important;
        transition: $property #{$time}ms ease !important;

        @if ($will_change != 0) {
            will-change: $property !important;
        }
    } @else {
        -webkit-transition: $property #{$time}ms ease;
        -moz-transition: $property #{$time}ms ease;
        -o-transition: $property #{$time}ms ease;
        transition: $property #{$time}ms ease;

        @if ($will_change != 0) {
            will-change: $property;
        }
    }
}
@mixin animate2($property1, $property2, $time, $will_change: 0, $important: 0) {
    @if ($important != 0) {
        -webkit-transition: $property1 #{$time}ms ease, $property2 #{$time}ms ease !important;
        -moz-transition: $property1 #{$time}ms ease, $property2 #{$time}ms ease !important;
        -o-transition: $property1 #{$time}ms ease, $property2 #{$time}ms ease !important;
        transition: $property1 #{$time}ms ease, $property2 #{$time}ms ease !important;

        @if ($will_change != 0) {
            will-change: $property1, $property2 !important;
        }
    } @else {
        -webkit-transition: $property1 #{$time}ms ease, $property2 #{$time}ms ease;
        -moz-transition: $property1 #{$time}ms ease, $property2 #{$time}ms ease;
        -o-transition: $property1 #{$time}ms ease, $property2 #{$time}ms ease;
        transition: $property1 #{$time}ms ease, $property2 #{$time}ms ease;

        @if ($will_change != 0) {
            will-change: $property1, $property2;
        }
    }
}

@mixin flex-child($flex: 1 0 auto) {
    // 1 0 auto – {grow} {shrink} {basis}
    -ms-flex: $flex;
    -mox-flex: $flex;
    -webkit-flex: $flex;
    flex: $flex;
}
@mixin flex($important: 0) {
    @if $important != 0 {
        display: -webkit-box !important;
        display: -webkit-flex !important;
        display: -ms-flexbox !important;
        display: flex !important;
    } @else {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }

    > * {
        position: relative;
        @include flex-child(0 0 auto);
    }

    overflow: auto;
    @include align-items();
}
@mixin align-items($a: center) {
    -moz-align-items: $a;
    -webkit-align-items: $a;
    align-items: $a;
}

@mixin ellipsis($max_width: 100%) {
    width: $max_width;
    max-width: $max_width;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

/*  Common standalone elements  */

$button_height: 36px;
.text_button,
.text_label {
    @include ibm();
    padding: 0 24px;
    line-height: $button_height;
    height: $button_height;
    text-align: center;
    border-radius: math.div($button-height, 2);
    text-decoration: none;
    cursor: pointer; // needed for <input>s
    outline: none;
    color: $grey;
    white-space: nowrap;

    .icon {
        @include max_wh(14px);

        &:first-child {
            margin-right: 10px;
        }
        &:last-child {
            margin-left: 10px;
        }
        &:last-child:first-child {
            margin: 0;
        }
    }
    span {
        font-size: 16px;
        font-weight: 400;
    }

    @include animate(background-color, $animation_t, 1);
    &:hover {
        background-color: $smoke;
    }

    &.submit_input {
        font-size: 16px;
        font-weight: 400;
    }

    &.selectable {
        > * {
            @include animate(opacity, $animation_t, 1);
        }

        &.selected {
            background-color: $button_green !important;

            > * {
                opacity: 0;
            }
            > .svg_check {
                opacity: 1;
            }
        }

        > .svg_check {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            fill: white;
            @include max_wh(16px);
            opacity: 0;
        }
    }
}

.text_label:hover {
    background-color: transparent;
    cursor: text;
}

.contained_button {
    @extend .text_button;
    color: white;
    background-color: $button_blue;
    &:hover {
        background-color: $button_hover_blue;
    }

    &.green {
        color: white;
        background-color: $button_green;
        &:hover {
            background-color: $button_hover_green;
        }
    }
}

.ghost_button {
    @extend .text_button;
    color: $button_blue;
    background-color: $off_white;
    &:hover {
        background-color: $smoke;
    }
}

.small_button {
    font-size: 13px;
    line-height: 20px;
    text-decoration: none;
    color: $light_grey;
    border-bottom: 1px solid $light_grey;
}

.submitted_hide {
    @include animate(opacity, 300, 1);
    .form_submitted & {
        opacity: 0;
    }
}
.submitted_loading {
    @include animate(background, 300, 1);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 40px;

    .form_submitted &,
    &.form_submitted {
        background-image: url($CDN + "/assets/loading-ball.gif");
    }
}

.form_submitted .submitted_prevent {
    display: none !important;
}

.click_loading {
    background-position: center center;
    background-repeat: no-repeat;
    @include animate(background, 300, 1);

    > * {
        @include animate(opacity, 300, 1);
    }

    &.clicked_loading {
        background-image: url($CDN + "/loading.gif");

        > * {
            opacity: 0;
        }
    }
}

/*  Composite, global elements  */

.block {
    text-align: center;
    padding: 20px;

    &:last-child {
        padding: 20px 20px 40px 20px;
    }

    @media only screen and (max-width: $mobile_width) {
        padding: 20px 0;
        &:last-child {
            padding: 20px 0 40px 0;
        }
    }
}

$card_width: 640px;
.card {
    @include ibm();
    width: 100%;
    max-width: $card_width;
    padding: 30px;
    background-color: white;
    box-shadow: 0 1px 4px -1px grey, 0 0 5px -2px grey;
    line-height: 1.5;
    text-align: left;
    border-radius: 2px;

    @media only screen and (max-width: $mobile_width) {
        padding-left: 16px;
        padding-right: 16px;
    }

    h1 {
        // font-size: 18px;
        font-size: 16px;
        font-weight: 400;
        color: $slate;
        margin-top: 24px;
        // margin-bottom: 16px;
        margin-bottom: 12px;

        strong {
            font-weight: 600;
            color: $button_blue;
        }

        @media only screen and (max-width: $mobile_width) {
            font-size: 17px;
        }
    }

    .black {
        color: $off_black;
    }
    .bold {
        font-weight: 600;
    }

    $row_image_pad: 20px;
    $row_image_pad_mobile: 10px;
    .list {
        $row_image_width: 44px;
        $row_button_width: 100px;

        padding: 0 $row_image_pad;

        @media only screen and (max-width: $mobile_width) {
            padding: 0 $row_image_pad_mobile;
        }

        .row {
            // padding: 20px 0;
            padding: 12px 0;
            border-bottom: 1px solid $smoke;

            &:last-child {
                border-bottom: 0;
            }

            > * {
                @include ibm();
            }
            .image {
                width: $row_image_width;
                line-height: $row_image_width;
                text-align: center;

                > * {
                    @include ibm();
                    max-width: 100%;
                    max-height: $row_image_width;
                }

                .initials {
                    @include wh(100%);
                    border-radius: 100%;
                    background-color: $nicejob_purple;
                    color: white;
                    font-size: 15px;
                    font-weight: 400;
                }

                .svg_circle {
                    @include max_wh(12px);
                    fill: $button_blue;
                }
            }
            .body {
                padding: 0 0 0 $row_image_pad;
                width: calc(100% - #{$row_image_width});

                &:first-child {
                    width: 100%;
                    padding: 0;
                }

                @include flex();
                display: inline-flex;
                flex-wrap: wrap;

                .content {
                    @include flex-child();

                    padding-right: $row_image_pad;
                    &:last-child {
                        padding-right: 0;
                        width: 100%;
                    }

                    h2 {
                        // @include ellipsis();
                        // font-size: 16px;
                        font-size: 15px;
                        font-weight: 600;
                        line-height: 24px;
                        color: $off_black;
                    }
                    h5 {
                        // @include ellipsis();
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 22px;
                        color: $mid_grey;
                    }

                    > .top > * {
                        @include ibm();
                        margin-right: 16px;

                        &:last-child {
                            margin-right: 0;
                        }
                    }

                    @media only screen and (max-width: $mobile_width) {
                        width: 100%;
                        padding-right: 0;

                        > .top > * {
                            display: block;
                            margin: 0;
                        }
                    }
                }

                .button {
                    margin: 4px 0;

                    @media only screen and (max-width: $mobile_width) {
                        margin: 8px 0;
                    }
                }

                @media only screen and (max-width: $mobile_width) {
                    flex-direction: row-reverse;
                }
            }
        }

        $bullet_width: 8px;
        &.bullet_list .row {
            // padding: 8px $row_image_pad;
            padding: 7px $row_image_pad;
            border-bottom: 0;

            @media only screen and (max-width: $mobile_width) {
                padding: 8px $row_image_pad_mobile;
            }

            .image {
                width: $bullet_width;
            }
            .body {
                width: calc(100% - #{$bullet_width});
                padding: 0 0 0 16px;
            }
        }

        $micro_row_image_width: 30px;
        &.micro_list .row {
            .image {
                @include wh($micro_row_image_width, true);

                > * {
                    max-height: $micro_row_image_width;
                }

                .initials {
                    font-size: 12px;
                    font-weight: 600;
                }
            }
            .body {
                .content {
                    h2 {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    p {
        font-size: 14px;
        line-height: 1.6;
        color: $slate;
        padding: 0 $row_image_pad 20px $row_image_pad;

        a {
            color: $button_blue;
            font-weight: 400;
        }

        @media only screen and (max-width: $mobile_width) {
            padding: 0 $row_image_pad_mobile 20px $row_image_pad_mobile;
        }
    }
    p.join {
        padding: 10px 20px 30px 20px;
        font-size: 17px;
        text-align: center;
    }
    p.invalid {
        padding: 10px 20px;
        font-size: 17px;
        line-height: 1.7;
        text-align: left;
    }

    form {
        text-align: center;
        .row {
            padding: 10px 20px;

            > * {
                @include ibm();
            }

            .text_input {
                display: block;
                height: 37px;
                width: 100%;
                padding: 5px;
                border-radius: 2px;
                outline: none;
                font-size: 15px;
                font-weight: 400;
                background-color: $off_white;

                &:focus {
                    background-color: $smoke;
                }
            }

            &.checkbox_row {
                text-align: left;
                input {
                    height: 16px;
                    width: 16px;
                }
                label {
                    font-size: 15px;
                    margin-left: 8px;
                    color: $slate;
                }
            }

            &.submit_row {
                margin-top: 10px;
                text-align: right;

                > * {
                    margin-left: 20px;
                }
            }
        }
    }

    .error {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid $smoke;
        color: crimson;
        font-size: 15px;
        text-align: center;

        > * {
            display: inline;
            vertical-align: middle;
        }

        strong {
            text-transform: uppercase;
            letter-spacing: 0.5px;
            font-size: 13px;
            padding-right: 6px;
        }
        span {
        }
    }

    .footer {
        padding: 16px $row_image_pad 0 $row_image_pad;
        margin-top: 16px;
        border-top: 1px solid $smoke;
        text-align: right;

        @media only screen and (max-width: $mobile_width) {
            padding: 16px $row_image_pad_mobile 0 $row_image_pad_mobile;
        }

        > * {
            margin: 0 6px;
        }
    }

    //  =====

    &.oauth_consent_card {
        // padding-top: 24px;
        padding-top: 16px;
    }

    .user_header {
        // padding-bottom: 12px;
        padding-bottom: 8px;
        border-bottom: 1px solid $smoke;
    }
    @media only screen and (max-width: $mobile_width) {
        .user_header {
        }
    }

    #post_company_select {
        margin-top: 8px;
        border-top: 1px solid $smoke;
        @include animate(opacity, $animation_t, 1);
        opacity: 0.2;

        .company_selected & {
            opacity: 1;
        }
    }

    #company_select_prompt {
        .company_preselected & {
            display: none;
        }
    }

    #allow_button {
        @include animate2(background-color, opacity, $animation_t, 1);

        &.disabled {
            opacity: 0.2;
            background-color: $button_blue;
            cursor: default;
            &:hover {
                background-color: $button_blue;
            }
        }
    }

    //  =====

    //  Mobile:
    .narrow & {
        max-width: $narrow_card_width;
    }
}

/*  Page-specific elements  */

.oauth_header {
    // padding: 40px 0 20px 0;
    padding: 30px 0 10px 0;
    text-align: center;

    > * {
        @include ibm();
    }

    $header_img_width: 40px;
    img {
        @include max_wh($header_img_width);
    }
    span {
        padding-left: 16px;
        // font-size: 15px;
        font-size: 14px;
        font-weight: 600;
        color: $grey;
        text-transform: uppercase;
        letter-spacing: 1.2px;
        // padding-right: $header_img_width;
    }
}
