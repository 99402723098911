//  Developer documentation
// --- Adapted from _common/scss/documentation.scss ---

@use "sass:math";
@import "../../../_common/scss/common.scss";

html {
    height: 100%;
    background-color: white;
}

body {
    height: 100%;
    font-size: 16px;
    color: $off_black;
}

#root {
    background-color: white;
    height: 100%;
}

//  Dimensions
$sidebar_width: 310px;
$sidebar_header_height: 66px;
$sidebar_footer_height: 64px;
$sandbox_width: 360px;
$toolbar_height: 40px;

$body_pad: 48px;
$body_pad_tablet: 24px;
$section_width: 644px + 2 * $body_pad;

//  @media thresholds
$TABLET: 767px;
$MOBILE: 499px;

//  Colours
$code_bg: rgba(27, 31, 35, 0.05);

/*  ELEMENTS  */
p,
.highlight,
.loom,
ul,
ol,
pre,
table {
    margin: 24px $body_pad 16px $body_pad;
    font-size: 16px;
    font-weight: 400;
    color: $off_black;
    line-height: 1.7;
    max-width: $section_width;
    text-align: justify;

    @media screen and (max-width: $TABLET) {
        margin: 24px $body_pad_tablet 16px $body_pad_tablet;
        font-size: 15px;
    }

    img {
        max-width: 100%;
    }
}

p > pre,
pre > pre {
    margin: 0;
}

h1,
h2,
h3 {
    margin: 30px $body_pad 16px;
    @media screen and (max-width: $TABLET) {
        margin: 30px $body_pad_tablet 16px;
    }
}

h1 {
    font-size: 38px;
    font-weight: 900;
    letter-spacing: 0.3px;
    color: black;
    line-height: 1.5;
    border-bottom: 1px solid #eaecef;
    padding-bottom: 6px;

    &:first-child {
        margin-top: 0;
    }
}

h2 {
    font-size: 32px;
    font-weight: 900;
    letter-spacing: 0.3px;
    color: black;
    line-height: 1.3;
    border-bottom: 1px solid #eaecef;
    padding-bottom: 10px;
}

h3 {
    font-size: 24px;
    font-weight: 600;
    color: #3d3d3d;
    line-height: 1.4;
    letter-spacing: 0.2px;
}

h4 {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    color: $grey;
    line-height: 24px;
    text-transform: uppercase;
    margin: 32px $body_pad 16px;

    @media screen and (max-width: $TABLET) {
        margin: 32px $body_pad_tablet 16px;
    }

    code {
        text-transform: none;
        letter-spacing: normal;
        font-size: 14px;
        background-color: rgba(27, 31, 35, 0.01);
    }
}

ul,
ol {
    padding-left: 24px;
}
li {
    display: list-item;
    padding-left: 8px;
    padding-top: 6px;

    &:first-child {
        padding-top: 0;
    }

    input[type="checkbox"] {
        margin: 0 8px 0 0;
        width: 18px;
        height: 18px;
    }

    //  Nested <ul>
    ul,
    ol {
        margin: 0;
        padding-top: 8px;
    }

    > p {
        margin: 0;
    }
}
li::marker {
    color: #4d4d4d;
    font-size: 14px;
}

hr {
    border-bottom: 1px solid $purple;
    padding-top: $body_pad;
    margin-bottom: $body_pad;
}

table {
    border-spacing: 0;
    border-collapse: collapse;

    tr:nth-child(2n) td {
        background-color: #f6f8fa;
    }
    tr {
        border-top: 1px solid #c6cbd1;
    }
    th,
    td {
        padding: 6px 13px;
        border: 1px solid #dfe2e5;
        background-clip: padding-box;
    }
    th {
        text-align: center;
        font-weight: 600;
    }
}

.break {
    height: 14px;
    background-color: $purple;
    margin-top: $body_pad;
    margin-bottom: $body_pad;
}

//  Pretty-print code
pre.prettyprint {
    // !important to overwrite default!
    padding: 12px 16px !important;
    border: 0 !important;
    background: #f4f4f4 !important;
    border-radius: 4px !important;
    line-height: 1.4 !important;
    font-size: 15px !important;
    overflow: auto;
}

code {
    background: $code_bg;
    border-radius: 4px;
    border: 0;
    font-family: monospace;
    margin: 0 2px;
    padding: 2px 6px;

    &:first-child {
        margin-left: 0;
    }
    &:last-child {
        margin-right: 0;
    }
}

//  <method>
.method {
    display: inline-block;
    background-color: $highlight_bg_grey;
    color: $slate;
    font-weight: 700 !important;
    border-radius: 2px;
    padding: 0 4px;
    font-size: 14px !important;
}
.method--get,
.method--redirect {
    background-color: $highlight_bg_green;
    color: $highlight_type_green;
}
.method--post {
    background-color: $highlight_bg_purple;
    color: $highlight_type_purple;
}
.method--delete {
    background-color: $highlight_bg_red;
    color: $highlight_type_red;
}

//  <loom>
.loom {
    height: 400px;
    iframe {
        width: 100%;
        height: 100%;
    }
}

//  <schema>

.entity-property--type {
    font-size: 14px;
    font-weight: 600;
    color: $highlight_type_green;
    background-color: transparent;

    &.entity-property--type--optional {
        font-style: italic;
        font-weight: 400;
    }
}

.entity-properties {
    margin-top: 4px;
    padding: 0;

    > li {
        display: block;
        text-align: left;
        padding: 4px 12px;
        border-bottom: 1px solid #f3f3f3;

        @media screen and (max-width: $TABLET) {
            padding: 4px 0;
        }

        &:nth-child(2n) {
            background-color: #fbfbfb;
        }

        > * {
            @include ibm();
            margin: 6px 0;
        }
    }

    .entity--property--unavailable {
        @extend .absFull;
        background-color: rgba(white, 0.8);
        opacity: 1 !important;

        span {
            @extend .centreVH;
            color: $slate;
            font-size: 16px;
            font-weight: 400;
            letter-spacing: 0.4px;
            width: 100%;
            padding: 0 32px;
            text-align: center;
        }
    }

    .entity-property {
        display: flex;
        align-items: start;

        @media screen and (max-width: $TABLET) {
            flex-wrap: wrap;
        }

        > * {
            margin: 4px 0;
        }

        .entity-property--name {
            min-width: 200px;
            line-height: 20px;
            word-break: break-all;
            padding: 3px 0; // matches <code> vertical padding

            code {
                font-size: 14px;
            }

            @media screen and (max-width: $TABLET) {
                min-width: 0;
                flex: 0 0 auto;
            }
        }

        .entity-property--type {
            //  Inherits from .entity-property--type above
            min-width: 128px;
            margin-left: 12px;
            margin-right: 8px;
            line-height: 20px;

            @media screen and (max-width: $TABLET) {
                min-width: 0;
            }
        }

        .entity-property--unique {
            font-size: 10px;
            display: inline-block;
            margin-left: 6px;
            padding: 3px 6px;
            border-radius: 2px;
            background-color: $highlight_bg_orange;
            color: $highlight_type_orange;
        }

        .entity-property--deprecated {
            font-size: 10px;
            display: inline-block;
            margin-left: 6px;
            padding: 3px 6px;
            border-radius: 2px;
            background-color: $highlight_bg_red;
            color: $highlight_type_red;
        }
    }

    .entity-property--description {
        font-size: 14px;
        line-height: 1.6;
        padding-left: 10px;
        text-align: justify;

        &.entity-property--description--block {
            display: block;
        }

        @media screen and (max-width: $TABLET) {
            margin-top: 6px;
            display: block;
        }
    }

    li.unavailable > * {
        opacity: 0.5;
    }
}

//  <scope>
.scope {
    display: inline-block;
    vertical-align: middle;
    background-color: $highlight_bg_green;
    color: $highlight_type_green;
    font-weight: 400;
    border-radius: 2px;
    padding: 0 5px;
    font-size: 14px;
    margin: 0 4px 6px 0;
    border: 0;
}

//  <highlight>
.highlight {
    border-radius: 4px;
    padding: 12px 16px;
    font-size: 15px;

    ul,
    ol,
    table {
        margin: 0;
    }

    li {
        display: list-item;
        font-size: 15px;
        padding: 4px 0 4px 4px;
        margin-left: 16px;
    }

    tr {
        border: 0 !important;
        background: none !important;
    }

    th,
    td {
        padding: 0 6px;
        border: 0 !important;
        background: none !important;
    }
}
.highlight--blue,
.scope--blue {
    background-color: $highlight_bg_blue;
    color: $highlight_type_blue;
}
.highlight--grey,
.scope--grey {
    background-color: $highlight_bg_grey;
    color: $highlight_type_grey;
}
.highlight--green,
.scope--green {
    background-color: $highlight_bg_green;
    color: $highlight_type_green;
}
.highlight--red,
.scope--red {
    background-color: $highlight_bg_red;
    color: $highlight_type_red;
}
.highlight--yellow,
.scope--yellow {
    background-color: $highlight_bg_yellow;
    color: $highlight_type_yellow;
}
.highlight--orange,
.scope--orange {
    background-color: $highlight_bg_orange;
    color: $highlight_type_orange;
}
.highlight--purple,
.scope--purple {
    background-color: $highlight_bg_purple;
    color: $highlight_type_purple;
}

// cURL
.highlight--curl {
    padding: 0;
    overflow: hidden;
    background-color: $highlight_type_grey;
    max-width: 620px;

    //  Request
    .curl--header,
    .curl--body {
        code {
            padding: 0;
            border: 0;
            border-radius: 0;
            margin: 0;
        }
    }

    .curl--header {
        background-color: $highlight_type_blue;
        color: white;
        font-size: 0;
        padding: 4px 0;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        cursor: pointer;

        &.curl--header--get,
        &.curl--header--redirect {
            background-color: $highlight_type_green;
        }
        &.curl--header--post {
            background-color: $highlight_type_purple;
        }
        &.curl--header--delete {
            background-color: $highlight_type_red;
        }

        * {
            @include ibm();
        }

        $curl_open_width: 40px;

        .curl--dest {
            width: calc(100% - #{$curl_open_width});
            max-width: calc(100% - #{$curl_open_width});
            overflow: auto;
            padding-left: 12px;
        }

        .curl--header--method {
            font-size: 14px;
            padding: 0 12px 0 0;

            &.curl--method--get,
            &.curl--method--redirect {
                color: $highlight_bg_green;
            }
            &.curl--method--post {
                color: $highlight_bg_purple;
            }
            &.curl--method--delete {
                color: $highlight_bg_red;
            }
        }

        .curl--header--url {
            font-size: 13px;
        }

        .curl--header--open {
            width: $curl_open_width;
            color: white;
            border: 0;
            font-size: 15px;
            text-align: center;
        }
    }
    .curl--body {
        color: white;

        &.curl--body--request {
            overflow: auto;
            max-height: none;
            padding: 8px 12px;
        }

        code {
            display: block;
            padding: 0 0 0 12px;
            white-space: nowrap;
            font-size: 13px;
            background-color: transparent;
        }

        code.curl--body--init {
            padding-left: 0;
        }
    }

    //  Response
    .curl--header--response {
        padding: 0;
        line-height: 36px;
        color: $slate;
        background-color: white;
        border-radius: 0;
        border-left: 1px solid $ccc;
        border-right: 1px solid $ccc;
        border-bottom: 1px solid $ccc;

        $curl_open_width: 40px;

        .curl--dest {
            font-size: 13px;
        }
        .curl--header--open {
            color: $slate;
        }
    }
    .curl--body--response {
        background-color: #ebecee;
        color: $highlight_type_grey;
        overflow: auto;
        max-height: none;
        padding: 8px 12px;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
    }

    //  Description
    .curl--description {
        color: $highlight_type_grey;
        background-color: $highlight_bg_grey;
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        padding: 16px;

        span {
            margin-bottom: 6px;
            display: block;
        }
        p {
            margin: 0;
            font-size: 15px;
            padding: 0 6px;
        }
    }
}

//  <smallcaps>
.smallcaps {
    text-transform: uppercase;
    font-size: 0.8em;
    opacity: 0.8;
    letter-spacing: 0.5px;
    font-weight: 600;
}

/*  SECTIONS  */
//  Sidebar
#sidebar {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    max-height: 100%;
    background-color: white;
    overflow: auto;
    width: $sidebar_width;
    transform: translateX(0);
    // @include animate(transform, 200, 1);
    z-index: 3;
    border-right: 1px solid #ccc;

    //  Tablet
    @media screen and (max-width: $TABLET) {
        display: none;
        // transform: translateX(-100%);
    }

    //  Element modifications
    a {
        border: 0;
    }
    p {
        margin: 0;
    }

    //  Content
    .sidebar--header {
        $sidebar_header_height__title: 20px;
        $sidebar_header_margin__updated: 4px;
        $sidebar_header_height__updated: 18px;
        $sidebar_header_vp: math.div(
            $sidebar_header_height - $sidebar_header_height__title - $sidebar_header_margin__updated -
                $sidebar_header_height__updated,
            2
        );

        @include lh($sidebar_header_height);
        padding-left: 16px;
        padding-top: $sidebar_header_vp;
        padding-bottom: $sidebar_header_vp;
        display: flex;
        align-items: center;

        .sidebar--header--img {
            max-width: 30px;
            margin-right: 11px;
        }

        .sidebar--header--content {
            flex: 1 0 auto;
        }

        .sidebar--header--title {
            line-height: $sidebar_header_height__title;
        }

        .sidebar--header--title--name {
            vertical-align: middle;
            font-size: 14px;
            font-weight: 600;
            color: #4b4b4b;
            margin-right: 5px;
        }
        .sidebar--header--title--version {
            font-size: 11px;
            vertical-align: top;
            margin-left: 4px;
            font-weight: 400;
            color: $grey;
        }

        .sidebar--header--updated {
            line-height: $sidebar_header_height__updated;
            font-size: 12px;
            padding: 0 8px 0 0;
            margin-top: $sidebar_header_margin__updated;
            color: #7a7a7a;
        }

        &.sidebar--header--logo-wrap {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0;

            img {
                display: inline-block;
                width: 40px;
            }
        }
    }

    .sidebar--body {
        height: calc(100% - #{$sidebar_header_height + $sidebar_footer_height});
        max-height: calc(100% - #{$sidebar_header_height + $sidebar_footer_height});
        overflow: auto;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        padding: 0;

        // --------------
        // v2

        .navigation--folder {
            padding: 12px 0;

            &.navigation--folder--root {
                padding: 18px 0;
                line-height: 20px;
            }

            $nav_left_pad: 16px;

            .navigation--folder--title {
                text-transform: uppercase;
                color: $mid_grey;
                font-size: 12px;
                font-weight: 600;
                padding: 6px $nav_left_pad 5px;
                letter-spacing: 0.7px;
            }

            .navigation--page {
                padding: 3px 0 0;

                .navigation--page--title {
                    align-items: center;

                    $nav_public_w: 16px;
                    $nav_public_lm: 6px;
                    $nav_public_rm: 16px;

                    &.navigation--page--title--active {
                        background-color: #f5eeff;
                        border-right: 1px solid $purple;

                        .navigation--page--title--text {
                            color: $purple !important;
                            background-color: transparent !important;

                            .navigation--page--title--public {
                                background-color: transparent;
                                color: $purple;
                            }
                        }
                    }

                    .navigation--page--title--text {
                        display: flex;
                        font-size: 14px;
                        line-height: 1.5;
                        color: #6a6a6a;
                        font-weight: 600;
                        text-decoration: none;
                        padding: 4px 10px 5px $nav_left_pad;
                        @include animate2(color, background-color, 250);

                        &:hover {
                            background-color: $highlight_bg_green;
                            color: $highlight_type_green;
                        }

                        span {
                            flex: 1 0 auto;
                            max-width: 100%;
                        }

                        .navigation--page--title--public {
                            flex: 0 0 auto;
                            margin-left: $nav_public_lm;
                            font-size: 10px;
                            text-align: center;
                            background-color: $highlight_bg_green;
                            color: $highlight_type_green;
                            line-height: $nav_public_w;
                            border-radius: 4px;
                            padding: 2px 4px;
                            text-transform: uppercase;
                            font-weight: 600;
                        }
                    }
                }

                .navigation--page--contents {
                    background-color: #f8f7fa;
                    padding: 5px 0;
                    border-right: 1px solid $purple;

                    .navigation--page--title {
                        .navigation--page--title--text {
                            padding-left: $nav_left_pad + 4;
                            font-weight: 600;
                            margin-right: 0;
                            border-radius: 0;

                            @include animate2(color, background-color, 250);
                            &:hover {
                                color: $purple;
                                background-color: #f5eeff;
                            }

                            &.navigation--page--title--text--3 {
                                padding-left: $nav_left_pad + 8;
                                font-weight: 400;
                            }

                            &.selected {
                                color: $highlight_type_blue !important;
                                background-color: $highlight_bg_blue !important;
                            }
                        }
                    }
                }
            }
        }

        // --------------
        // v1

        //  Link groups
        $sidebar_group_padding_bottom: 12px;

        .sidebar--group {
            padding-bottom: $sidebar_group_padding_bottom;

            &.sidebar--group--nested {
                padding-bottom: 0;
            }

            a {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                @include animate2(color, background-color, 200);

                &:hover {
                    color: $off_black;
                }

                &.selected {
                    color: $purple;
                    background-color: $light_purple;
                }
            }
        }

        .sidebar--entity--group {
            padding-bottom: 8px;

            &.hidden {
                display: none;
            }
        }

        li {
            padding-right: 12px;
        }

        li.sidebar--link--list {
            padding-right: 0;
            padding-bottom: $sidebar_group_padding_bottom;

            &.hidden {
                display: none;
            }
        }

        //  Links
        .sidebar--link--header {
            color: $mid_grey;
            font-size: 12px;
            padding: 16px 0 10px 16px;
            font-weight: 600;
            line-height: 16px;
            text-transform: uppercase;
            letter-spacing: 0.7px;
            cursor: pointer;

            .link--header--arrow {
                color: $light_grey;
                float: right;
                margin-right: 16px;
            }

            .link--header--arrow--to-open {
                font-size: 10px;
                display: none;
            }
            .link--header--arrow--to-close {
                // display: none;
                font-size: 17px;
                margin-top: -1px;
            }
        }

        .sidebar--link--entity {
            display: block;
            line-height: 20px;
            padding: 4px 16px;
            margin-top: 2px;
            font-weight: 600;
            color: $slate;
            font-size: 14px;
        }
        .sidebar--link--method {
            display: block;
            line-height: 18px;
            padding: 4px 16px 4px 28px;
            font-weight: 400;
            color: $grey;
            font-size: 14px;
        }
    }

    .sidebar--footer {
        background-color: $purple;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: $sidebar_footer_height;
        line-height: 1.5;

        $svg_w: 12px;
        > * {
            display: flex;
            padding-left: 16px;
            padding-right: 12px;
            align-items: center;

            p {
                line-height: 1.5;
            }

            > div {
                padding-right: 10px;
                width: calc(100% - #{$svg_w});
            }

            > svg {
                width: $svg_w;
                height: $svg_w;
                fill: white;
            }
        }

        //  Login
        .sidebar--footer--login-link {
            p {
                color: white;
                font-size: 15px;
            }
        }

        //  User display
        .sidebar--footer--user {
            .sidebar--footer--user--company {
                font-weight: 600;
                color: white;
                font-size: 14px;

                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .sidebar--footer--user--email {
                margin-top: 4px;
                line-height: 1.5;
                font-weight: 400;
                color: #f6f6f6;
                font-size: 13px;
            }
        }
    }

    .sidebar--button {
        display: inline-block;
        vertical-align: middle;
        background-color: $highlight_bg_blue;
        color: $highlight_type_blue;
        font-weight: 400;
        border-radius: 2px;
        padding: 0 5px;
        font-size: 12px;
        margin: 0;
        border: 0;
    }
}

//  Toolbar (tablet and below)
#toolbar {
    display: none;
    position: fixed;
    top: 0;
    left: $sidebar_width;
    width: calc(100% - #{$sidebar_width});
    @include lh($toolbar_height);
    background-color: white;
    padding: 0;
    z-index: 100;
    border-bottom: 1px solid #ccc;

    //  Tablet
    @media screen and (max-width: $TABLET) {
        display: block;
        left: 0;
        width: 100%;
    }

    //  Elements
    $toolbar_link_width: 88px;

    > * {
        @include ibm();
    }

    select {
        appearance: none;
        -webkit-appearance: none;
        width: calc(100% - #{$toolbar_link_width});
        padding: 0 16px;
        @include lh($toolbar_height - 2);

        font-size: 12px;
        letter-spacing: 0.2px;
        font-weight: 600;
        color: $slate;

        //  Arrow background
        background-color: white;
        background-image: url(data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%230%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.54%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E);
        background-repeat: no-repeat;
        background-position: calc(100% - 9px) center;
    }

    option {
        text-transform: none;
    }

    > a {
        width: $toolbar_link_width;
        border-left: 1px solid #ccc;
        text-align: center;
        font-size: 14px;
        color: $button_blue;
        font-weight: 400;

        span {
            border-bottom: 1px solid;
            padding-bottom: 1px;
        }
    }

    //  Portal
    &.toolbar--portal {
        text-align: center;
    }
    .toolbar--portal--link {
        width: auto;
        border: 0;
        padding: 0 8px;
    }

    //  Link style
    &.toolbar--links {
        overflow: hidden;
        border-bottom: 0;

        &.toolbar--links--open {
            overflow: visible;
        }
    }
    .toolbar--links--header {
        display: block;
        background: white;
        z-index: 1;
        border-bottom: 1px solid #ccc;
        height: $toolbar_height;

        > div {
            @include ibm();
            @include lh($toolbar_height);
            width: $toolbar_height;
            border-right: 1px solid #ccc;
            cursor: pointer;
        }

        svg {
            @extend .centreVH;
            width: 18px;
            height: 18px;
            fill: #7a7a7a;
        }
    }
    .toolbar--links--group {
        position: absolute;
        top: $toolbar_height - 1;
        left: 0;
        width: 100%;
        background-color: white;
        box-shadow: 0 0 10px -1px #7a7a7a;
        border-top: 1px solid #ccc;

        a {
            display: block;
            padding: 8px 16px;
            font-size: 13px;
            color: #2a2a2a;
            line-height: 28px;
            font-weight: 400;
        }
    }
}

//  Main body
#body {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding: $body_pad 0 $body_pad $sidebar_width;
    width: 100%;
    max-width: $sidebar_width + $section_width + 2 * $body_pad;
    min-height: 100%;
    background-color: white;

    @include animate(opacity, 150);

    //  Tablet
    @media screen and (max-width: $TABLET) {
        top: $toolbar_height;
        margin-left: 0;
        max-width: 100%;
        padding: $body_pad 0;
    }

    a {
        color: $button_blue;
        font-weight: 400;
        padding-bottom: 2px;
        border-bottom: 1px solid $button_blue;

        &.local {
            border-bottom: 2px dotted $button_blue;
        }
    }
}

// ----- 404 -----
.page-404 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;

    img {
        width: 200px;
        max-width: 90%;
    }

    p {
        font-size: 20px;
        font-weight: 600;
        padding: 12px 20px;
    }
}
