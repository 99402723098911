// Adapted from: inbound-oauth-route/scss
@use "sass:math";

$off_white: #FAFAFB;
$smoke: #eee;
$off_black: #1E2024;
$slate: #4b4b4b;
$grey: #6d6d6d;
$mid_grey: #828B98;
$light_grey: #bbb;
$ccc: #ccc;
$button_blue: #1874FD;
$button_hover_blue: #0084d4;

$highlight_bg_blue: #e1f5fd;
$highlight_type_blue: #00579b;
$highlight_bg_grey2: #f8f8f8;
$highlight_bg_grey: #f6f6f6;
$highlight_type_grey: #4b4b4b;
$highlight_bg_purple: #EADEFC;
$highlight_type_purple: #1B053D;
$highlight_bg_green: #E0FAEC;
$highlight_type_green: #083A20;
$highlight_bg_red: #FFEBEB;
$highlight_type_red: #520300;
$highlight_bg_yellow: #FFF5CC;
$highlight_type_yellow: #665200;
$highlight_bg_orange: #fdebdf;
$highlight_type_orange: #b75a1c;

$very_light_blue: #f8fdff;
$nicejob_purple: #4c2080;
$purple: #5810CB;
$light_purple: rgb(237, 231, 246);
$button_green: #299429;
$button_hover_green: green;

$narrow_card_width: 432px;
$animation_t: 300;

$mobile_width: 640px;

$CDN: "https://cdn.nicejob.co";

* {
    position: relative;
    padding: 0;
    border: 0;
    margin: 0;
    box-sizing: border-box;
}

body {
    font-family: Inter, Roboto, Helvetica, Arial, sans-serif;
    font-size: 0;
    min-height: 100%;
    background-color: $off_white;
}

input,
*:focus {
    outline: none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
}
input[type="radio"] {
    -webkit-appearance: radio !important;
    appearance: radio !important;
}

input[type="checkbox"] {
    -webkit-appearance: checkbox !important;
    appearance: checkbox !important;
}

a {
    text-decoration: none;
}

code {
    font-family: "Courier New", Courier, monospace;
    font-size: 0.92em;
    padding: 0 2px;
    padding: 3px 6px;
    vertical-align: bottom;
    border-radius: 2px;
    border: 1px solid #eee;
}

@mixin lh($h) {
    height: $h;
    line-height: $h;
}

@mixin wh($wh, $lh: 0) {
    width: $wh;
    height: $wh;

    @if ($lh != 0) {
        line-height: $wh;
    }
}
@mixin max_wh($wh, $lh: 0) {
    max-width: $wh;
    max-height: $wh;

    @if ($lh != 0) {
        line-height: $wh;
    }
}
@mixin ibm() {
    position: relative;
    display: inline-block;
    vertical-align: middle;
}
@mixin ibt() {
    position: relative;
    display: inline-block;
    vertical-align: top;
}
@mixin animate($property, $time, $will_change: 0, $important: 0) {
    @if ($important != 0) {
        -webkit-transition: $property #{$time}ms ease !important;
        -moz-transition: $property #{$time}ms ease !important;
        -o-transition: $property #{$time}ms ease !important;
        transition: $property #{$time}ms ease !important;

        @if ($will_change != 0) {
            will-change: $property !important;
        }
    } @else {
        -webkit-transition: $property #{$time}ms ease;
        -moz-transition: $property #{$time}ms ease;
        -o-transition: $property #{$time}ms ease;
        transition: $property #{$time}ms ease;

        @if ($will_change != 0) {
            will-change: $property;
        }
    }
}
@mixin animate2($property1, $property2, $time, $will_change: 0, $important: 0) {
    @if ($important != 0) {
        -webkit-transition: $property1 #{$time}ms ease, $property2 #{$time}ms ease !important;
        -moz-transition: $property1 #{$time}ms ease, $property2 #{$time}ms ease !important;
        -o-transition: $property1 #{$time}ms ease, $property2 #{$time}ms ease !important;
        transition: $property1 #{$time}ms ease, $property2 #{$time}ms ease !important;

        @if ($will_change != 0) {
            will-change: $property1, $property2 !important;
        }
    } @else {
        -webkit-transition: $property1 #{$time}ms ease, $property2 #{$time}ms ease;
        -moz-transition: $property1 #{$time}ms ease, $property2 #{$time}ms ease;
        -o-transition: $property1 #{$time}ms ease, $property2 #{$time}ms ease;
        transition: $property1 #{$time}ms ease, $property2 #{$time}ms ease;

        @if ($will_change != 0) {
            will-change: $property1, $property2;
        }
    }
}

@mixin flex-child($flex: 1 0 auto) {
    // 1 0 auto – {grow} {shrink} {basis}
    -ms-flex: $flex;
    -mox-flex: $flex;
    -webkit-flex: $flex;
    flex: $flex;
}
@mixin flex($important: 0) {
    @if $important != 0 {
        display: -webkit-box !important;
        display: -webkit-flex !important;
        display: -ms-flexbox !important;
        display: flex !important;
    } @else {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }

    > * {
        position: relative;
        @include flex-child(0 0 auto);
    }

    overflow: auto;
    @include align-items();
}
@mixin align-items($a: center) {
    -moz-align-items: $a;
    -webkit-align-items: $a;
    align-items: $a;
}

@mixin ellipsis($max_width: 100%) {
    width: $max_width;
    max-width: $max_width;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

/*  Common standalone elements  */

$button_height: 36px;
.text_button {
    @include ibm();
    padding: 0 24px;
    line-height: $button_height;
    height: $button_height;
    text-align: center;
    border-radius: math.div($button-height, 2);
    text-decoration: none;
    cursor: pointer; // needed for <input>s
    outline: none;
    color: $grey;
    white-space: nowrap;

    .icon {
        @include max_wh(14px);

        &:first-child {
            margin-right: 10px;
        }
        &:last-child {
            margin-left: 10px;
        }
        &:last-child:first-child {
            margin: 0;
        }
    }
    span {
        font-size: 16px;
        font-weight: 400;
    }

    @include animate(background-color, $animation_t, 1);
    &:hover {
        background-color: $smoke;
    }

    &.submit_input {
        font-size: 16px;
        font-weight: 400;
    }

    &.selectable {
        > * {
            @include animate(opacity, $animation_t, 1);
        }

        &.selected {
            background-color: $button_green !important;

            > * {
                opacity: 0;
            }
            > .svg_check {
                opacity: 1;
            }
        }

        > .svg_check {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            fill: white;
            @include max_wh(16px);
            opacity: 0;
        }
    }
}

.contained_button {
    @extend .text_button;
    color: white;
    background-color: $button_blue;
    &:hover {
        background-color: $button_hover_blue;
    }

    &.green {
        color: white;
        background-color: $button_green;
        &:hover {
            background-color: $button_hover_green;
        }
    }
}

.ghost_button {
    @extend .text_button;
    color: $button_blue;
    background-color: $off_white;
    &:hover {
        background-color: $smoke;
    }
}

.small_button {
    font-size: 13px;
    line-height: 20px;
    text-decoration: none;
    color: $light_grey;
    border-bottom: 1px solid $light_grey;
}

.absFull {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.centreVH {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

input.hidden {
    position: absolute;
    visibility: hidden;
    z-index: -1;
    height: 1px;
    width: 1px;
    overflow: hidden;
}

.hide {
    display: none !important;
}

.background_loading_gif--button {
    background-image: url("https://cdn.nicejob.co/assets/button-loading.gif");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 32px auto;
    color: transparent;
}

.flexbox {
    position: relative;
    display: flex;
    align-items: center;

    > * {
        flex: 0 0 auto;
    }
}
