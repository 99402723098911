//  Developer documentation
@import "./documentation-markdown.scss";

.body--portal {
    .portal--credentials {
        > div {
            display: flex;
            align-items: flex-start;
            padding: 5px 0px;

            .highlight--label {
                flex: 0 0 122px;
            }

            * {
                word-break: break-all;
            }
        }
    }

    input[type="text"],
    input[type="email"] {
        display: block;
        width: 100%;
        background-color: $highlight_bg_grey;
        padding: 12px 8px;
        font-size: 15px;
        border-bottom: 1px solid #2b2b2b;
    }

    /*  Legacy  */
    .redirect-uri--list {
        padding: 0;

        .redirect-uri--item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 8px 0;

            &:first-child {
                padding-top: 2px;
            }
        }
        .redirect-uri--item-member {
            border: 0;
            justify-content: flex-start;
        }

        $redirect_delete_width: 36px;
        $redirect_delete_marg: 12px;

        .redirect-uri--uri {
            display: block;
            width: 100%;
            max-width: calc(100% - #{$redirect_delete_width + $redirect_delete_marg});
            color: $highlight_type_grey;
            word-break: break-word;
        }
        input.redirect-uri--uri {
            width: calc(100% - #{$redirect_delete_width + $redirect_delete_marg});
        }
        input.redirect-uri--uri--full {
            width: 100%;
            max-width: 100%;
        }

        .redirect-uri--member {
            padding: 0 6px;
        }

        .redirect-uri--button {
            @include ibm();
            width: $redirect_delete_width;
            font-size: 17px;
            border: 0;
            padding: 2px 4px;
            text-align: center;

            svg {
                @include ibm();
                fill: $highlight_type_green;
                width: 14px;
                height: 14px;
            }

            &.redirect-uri--delete svg {
                fill: $highlight_type_red;
            }
        }
        .redirect-uri--delete {
            color: $highlight_type_red;
        }
        .redirect-uri--save {
            color: $highlight_type_green;
            font-size: 12px;
        }

        .access-token--item {
            display: block;
            margin-top: 10px;
            text-align: left;

            .access-token--item--code {
                display: inline-block;
                margin: 0 4px 6px 0;
                padding: 0 6px;
                line-height: 22px;
            }

            .access-token--item--date {
                line-height: 24px;
                font-size: 13px;
                word-break: break-word;
            }
        }
    }

    .error--name {
        font-weight: 400;
    }
    .error--url {
        display: block;
        border: 0;
        word-break: break-word;
    }

    .creds--submit {
        text-align: right;
    }

    form.form--submitted {
        opacity: 0.5;
    }
}
